import { AES, enc } from "crypto-js";
import { isEmpty, isObject } from "lodash";

const ENCRYPTION_KEY = process.env.VUE_APP_ENCRYPTION_KEY;

export const getData = (key) => {
  try {
    let userString = window.localStorage.getItem(key);
    let userArr = JSON.parse(userString);
    return userArr;
  } catch (err) {
    return new Object();
  }
};

export const deleteData = (key) => {
  window.localStorage.removeItem(key);
};

export const saveData = (key, data) => {
  window.localStorage.removeItem(key);
  window.localStorage.setItem(key, JSON.stringify(data));
};

export const saveEncrypt = (sessionkey, data) => {
  try {
    if (isEmpty(data) === false && isObject(data)) {
      let encrypted = AES.encrypt(
        JSON.stringify(data),
        ENCRYPTION_KEY
      ).toString();
      saveData(sessionkey, encrypted);
    }
  } catch (error) {
    console.warn({ error });
  }
};

export const getEncrypt = (sessionkey) => {
  try {
    let encrypted = getData(sessionkey);
    let decrypted = AES.decrypt(encrypted, ENCRYPTION_KEY);
    return JSON.parse(decrypted.toString(enc.Utf8));
  } catch (error) {
    return {};
  }
};

export const getConfig = (key) => {
  const appConfig = getData("app-config");
  return appConfig[key] || null;
};

export default {
  saveData,
  getData,
  deleteData,
  saveEncrypt,
  getEncrypt,
  getConfig,
};
